import request from './request'

// 睿美
// 获取订单列表
export const getOrderLists = (data) => {
  return request.post('/rame/gsfms/getFWOrders', data)
}
export const getcitylist = () => {
  return request.get('/rame/getCities')
}
export const getProgressList = (data) => {
  return request.post('/rame/business/getProgress', data)
}

// 以前的
export const getOrderList = (data) => {
  return request.post('/jf-tech/v1/getOrders', data)
}

export const editOrder = (data) => {
  return request.post('/jf-tech/v1/updateOrder', data)
}

export const exportFile = (data) => {
  return request.get('/download', {
    params: data
  })
}

export const getExpressDetail = (data) => {
  return request.post('/jf-tech/v1/getSFRoutes', data)
}

export const sendSfOrder = (data) => {
  return request.post('/jf-tech/v1/createSFOrder', data)
}

export const SFIntercept = (data) => {
  return request.post('/jf-tech/v1/SFIntercept', data)
}

export const SfCancelOrder = (data) => {
  return request.post('/jf-tech/v1/cancelSFOrder', data)
}
